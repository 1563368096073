<template>
  <div>
      <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'" id="printTest" style="height: 100vw">
          <el-row >
              <el-col :span="12">
                  <div id="iqchomequanb" style="width: 100%; margin: auto;height: 25vw;"></div>
              </el-col>
              <el-col :span="12">
                  <div id="iqchomdaoqi" style="width: 100%; margin: auto;height: 25vw;"></div>
              </el-col>
          </el-row>
          <el-row >
              <el-col :span="24">
                  <div id="iqchomzhuzhuang" style="width: 100%; margin: auto;height: 25vw;"></div>
              </el-col>
          </el-row>
          <el-row >
              <el-col :span="24">
                  <el-button size="small" @click="Ruetdata"  type="text">{{$t('All.重置')}}</el-button>
                  <div id="iqchomtibiao" style="width: 100%; margin: auto;height: 18vw;"></div>
              </el-col>
          </el-row>
      </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isqpisboot: false,
      // 全部数据
      qbdata: {
        title: {
          text: 'Nienyi-QC',
          subtext: this.$t('All.系统现有数据'),
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: this.$t('All.现有数据'),
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'MSDS' },
              { value: 735, name: this.$t('All.检验设备') },
              { value: 580, name: this.$t('All.标准样品') }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      // 指定图表的配置项和数据
      daoqitix: {
        title: {
          text: 'Nienyi-QC',
          subtext: this.$t('IQC.所有数据类型'),
          left: 'center'
        },
        tooltip: {},
        legend: {
          data: ['FQC', 'IQC', 'OQC', 'IPQC']
        },
        xAxis: {
          data: ['FQC', 'IQC', 'OQC', 'IPQC']
        },
        yAxis: {},
        series: [
          {
            name: this.$t('All.未结案'),
            type: 'bar',
            label: {
              show: true,
              formatter (param) {
                // correct the percentage
                // console.log(param)
                return param.value
              }
            },
            data: [5, 20, 36]
          }
        ]
      },
      shiyongzt: {
        title: {
          text: 'Nienyi-QC UsageLine'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['FQC', 'IQC', 'OQC', 'IPQC']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          name: this.$t('All.月份'),
          type: 'category',
          boundaryGap: false,
          data: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'MSDS',
            type: 'line',
            stack: 'Total',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
          {
            name: this.$t('All.检验设备'),
            type: 'line',
            stack: 'Total',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
          {
            name: this.$t('All.标准样品'),
            type: 'line',
            stack: 'Total',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 110, 0, 0, 0]
          },
          {
            name: this.$t('All.标准样品'),
            type: 'line',
            stack: 'Total',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 110, 0, 0, 0]
          }
        ]
      },
      zhuzhuang: {
        dataZoom: [
          {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter',
            start: 0,
            end: 1
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: [this.$t('IQC.总量'), this.$t('IQC.直通率'), this.$t('IQC.允收率'), this.$t('IQC.全检率'), this.$t('IQC.拒收率')]
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: ['2012', '2013', '2014', '2015', '2016']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Forest',
            type: 'bar',
            barGap: 0,
            label: 'labelOption',
            emphasis: {
              focus: 'series'
            },
            data: [320, 332, 301, 334, 390]
          },
          {
            name: 'Steppe',
            type: 'bar',
            label: 'labelOption',
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191, 234, 290]
          },
          {
            name: 'Desert',
            type: 'bar',
            label: 'labelOption',
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201, 154, 190]
          },
          {
            name: 'Wetland',
            type: 'bar',
            label: 'labelOption',
            emphasis: {
              focus: 'series'
            },
            data: [98, 77, 101, 99, 40]
          }
        ]
      },
      cq: '',
      state: '',
      rq: '',
      cs: ''
    }
  },
  components: {
  },
  created () {
  },
  mounted () {
    this.Getdata()
    this.Getdaoqidata()
    this.Getdaoqidata2()
    this.Getdaoqiyuefen()
  },
  methods: {
    async Getdata () {
      try {
        const { data: res } = await this.$http.get(`/api/Numericaliqc/Getdata?cq=${this.cq}&state=${this.state}&rq=${this.rq}`)
        if (res.status !== 200) return this.$message.error(res.msg)
        const myChart = this.$echarts.init(document.getElementById('iqchomequanb'))
        window.onresize = function () {
          myChart.resize()
        }
        this.qbdata.series[0].data = res.response
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.qbdata)
        const vthsi = this
        myChart.on('click', function (params) {
          // 控制台打印数据的名称
          vthsi.cs = params.data.name
          vthsi.Getdata()
          vthsi.Getdaoqidata()
          vthsi.Getdaoqidata2()
          vthsi.Getdaoqiyuefen()
        })
      } catch (error) {
        return this.$message.error(error)
      }
    },
    async Getdaoqidata () {
      try {
        const { data: res } = await this.$http.get(`/api/Numericaliqc/Getdaoqidata?cq=${this.cq}&cs=${this.cs}&state=${this.state}&rq=${this.rq}`)
        if (res.status !== 200) return this.$message.error(res.msg)
        // 基于准备好的dom，初始化echarts实例
        const myChart = this.$echarts.init(document.getElementById('iqchomdaoqi'))
        window.onresize = function () {
          myChart.resize()
        }
        this.daoqitix.series[0].data = res.response
        this.daoqitix.xAxis.data = res.name
        this.daoqitix.legend.data = res.name
        this.daoqitix.tooltip = res.name
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.daoqitix)
        const vthsi = this
        myChart.on('click', function (params) {
          switch (params.name) {
            case this.$t('IQC.完结允收'):
              vthsi.state = '2'
              break
            case this.$t('All.驳回'):
              vthsi.state = '1'
              break
            case this.$t('All.待审'):
              vthsi.state = '0'
              break
            case this.$t('All.暂存'):
              vthsi.state = '3'
              break
            case this.$t('IQC.退货'):
              vthsi.state = '4'
              break
            case this.$t('All.紧急提交'):
              vthsi.state = '8'
              break
            case this.$t('All.紧急核可'):
              vthsi.state = '7'
              break
            case this.$t('IQC.特采'):
              vthsi.state = '5'
              break
            case this.$t('All.紧急送呈'):
              vthsi.state = '6'
              break
            default:
              break
          }
          vthsi.Getdata()
          vthsi.Getdaoqiyuefen()
        })
      } catch (error) {
        return this.$message.error(error)
      }
    },
    async Getdaoqidata2 () {
      try {
        const { data: res } = await this.$http.get(`/api/Numericaliqc/Getdaoqidata2?cq=${this.cq}&cs=${this.cs}&state=${this.state}&rq=${this.rq}`)
        if (res.status !== 200) return this.$message.error(res.msg)
        // 基于准备好的dom，初始化echarts实例
        const myChart = this.$echarts.init(document.getElementById('iqchomzhuzhuang'))
        window.onresize = function () {
          myChart.resize()
        }
        this.zhuzhuang.series = res.response
        this.zhuzhuang.xAxis[0].data = res.name
        const labelOption = {
          show: true,
          position: 'insideBottom',
          distance: 15,
          align: 'left',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: '{c}  {name|{a}}',
          fontSize: 16,
          rich: {
            name: {}
          }
        }
        this.zhuzhuang.series.forEach(item => {
          item.label = labelOption
        })
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.zhuzhuang)
      } catch (error) {
        return this.$message.error(error)
      }
    },
    async Getdaoqiyuefen () {
      try {
        const { data: res } = await this.$http.get(`/api/Numericaliqc/Getdaoqiyuefen?cq=${this.cq}&state=${this.state}&cs=${this.cs}`)
        if (res.status !== 200) return this.$message.error(res.msg)
        const myChart = this.$echarts.init(document.getElementById('iqchomtibiao'))
        window.onresize = function () {
          myChart.resize()
        }
        this.shiyongzt.series = res.response
        this.shiyongzt.legend.data = res.name
        this.shiyongzt.xAxis.data = res.yuefen
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.shiyongzt)
        const vthsi = this
        myChart.on('click', function (params) {
          // 控制台打印数据的名称
          switch (params.seriesName) {
            case this.$t('All.太仓厂'):
              vthsi.cq = 'TC'
              break
            case this.$t('All.珠海厂'):
              vthsi.cq = 'ZH'
              break
            case this.$t('All.越南厂'):
              vthsi.cq = 'VN'
              break
            default:
              break
          }
          vthsi.rq = params.name
          vthsi.Getdata()
          vthsi.Getdaoqidata()
          vthsi.Getdaoqidata2()
          vthsi.Getdaoqiyuefen()
        })
      } catch (error) {
        return this.$message.error(error)
      }
    },
    Ruetdata () {
      this.cq = ''
      this.state = ''
      this.rq = ''
      this.cs = ''
      this.Getdata()
      this.Getdaoqidata()
      this.Getdaoqidata2()
      this.Getdaoqiyuefen()
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
}
</style>
